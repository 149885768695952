
import { defineComponent } from 'vue';
//import homeView from './views/homeView.vue';
import fpcView from './views/fpcView.vue';

export default defineComponent({
  name: 'App',
  components: {
    fpcView
  }
});
