
import { defineComponent, ref } from 'vue';
import { FirstPersonController } from "@/components/FirstPersonController";

export default defineComponent({
  name: 'fpcView',
  mounted(){
    const canvas  = document.querySelector("canvas")!;
    new FirstPersonController(canvas);
  }
});
