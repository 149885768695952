import {Scene,Engine,SceneLoader,Vector3,HemisphericLight,FreeCamera, MeshBuilder, StandardMaterial, Texture, PBRMaterial, ShadowGenerator} from "@babylonjs/core";
  import "@babylonjs/loaders";
  
  export class FirstPersonController {
    scene: Scene;
    engine: Engine;
  
    constructor(private canvas: HTMLCanvasElement) {
      this.engine = new Engine(this.canvas, true);
      this.scene = this.CreateScene();
      //this.CreateEnvironment();
      this.CreateController();
      this.FNp();
      this.FNm();
      this.FNf();
      this.FNs();
      this.FNm1()
      this.FNm2()
      this.FNc()
      this.FNsky()
      this.FNlab()
      this.engine.runRenderLoop(() => {
        this.scene.render();
        this.engine.resize();
      });
    }

    async FNp(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "portal-uv.glb");
      model.meshes[0].position = new Vector3(0, 0, -10)
      model.meshes[0].scaling = new Vector3 (2,2,2);
      model.meshes[0].checkCollisions = true;
    }
    async FNm(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "monolito-uv.glb");
      model.meshes[0].position = new Vector3(15, 0, 20)
      model.meshes[0].scaling = new Vector3 (3,3,3);
      model.meshes[0].checkCollisions = true;
    }
    async FNf(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "fuente-uv.glb");
      model.meshes[0].position = new Vector3(-15, 0.1, 20)
      model.meshes[0].scaling = new Vector3 (2,2,2);
      model.meshes[0].checkCollisions = true;
    }
    async FNs(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "sala-uv.glb");
      model.meshes[0].position = new Vector3(0, -0.1, 80)
      model.meshes[0].scaling = new Vector3 (3,3,3);
      model.meshes[0].checkCollisions = true;
    }
    async FNm1(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "mountains-uv.glb");
      model.meshes[0].position = new Vector3(150, 0, 400)
      model.meshes[0].scaling = new Vector3 (8,8,8);
      model.meshes[0].checkCollisions = true;
    }
    async FNm2(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "mountains-uv.glb");
      model.meshes[0].position = new Vector3(-150, 0, 400)
      model.meshes[0].scaling = new Vector3 (8,8,8);
      model.meshes[0].checkCollisions = true;
    }
    async FNc(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "cloud-uv.glb");
      model.meshes[0].position = new Vector3(30, 40, 200)
      model.meshes[0].scaling = new Vector3 (8,8,8);
      model.meshes[0].checkCollisions = true;
    }
    async FNsky(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "sky01.glb");
      model.meshes[0].position = new Vector3(0, 0, 0)
      model.meshes[0].scaling = new Vector3 (5,5,5);
      model.meshes[0].checkCollisions = true;
    }
    async FNlab(): Promise<void> {
      const model = await SceneLoader.ImportMeshAsync("", "./models/", "mat-instruments-step-06.glb");
      model.meshes[0].position = new Vector3(0, 0, 0)
      model.meshes[0].checkCollisions = true;
    }
    
    CreateScene(): Scene {
      const scene = new Scene(this.engine);
      new HemisphericLight("hemi", new Vector3(0, 1, 0), this.scene);

      const ground = MeshBuilder.CreateGround("ground", { width: 1000, height: 1000}, this.scene);
      ground.position = new Vector3(0, 0, -1)
      //const groundmaterial = new StandardMaterial("groundmaterial", this.scene);
      const groundmaterial = new StandardMaterial("groundmaterial", this.scene);
      //diffuse
      const diffuse = new Texture("./textures/stone/stone_diffuse.jpg", this.scene);
      diffuse.uScale = 96;
      diffuse.vScale = 96;
      groundmaterial.diffuseTexture = diffuse;
      ground.material = groundmaterial;
      ground.checkCollisions = true;

      scene.onPointerDown = (evt) => {
        if (evt.button === 0) this.engine.enterPointerlock();
        if (evt.button === 1) this.engine.exitPointerlock();
      };
  
      const framesPerSecond = 60;
      const gravity = -9.81;
      scene.gravity = new Vector3(0, gravity / framesPerSecond, 0);
      scene.collisionsEnabled = true;
  
      
      return scene;
    }
  
    /*async CreateEnvironment(): Promise<void> {
      const { meshes } = await SceneLoader.ImportMeshAsync(
        "",
        "./models/",
        "level01.glb",
        this.scene
      );
      meshes.map((mesh) => {
        mesh.checkCollisions = true;
      });
    }*/
  
    CreateController(): void {
      const camera = new FreeCamera("camera", new Vector3(0, 1, -40), this.scene);
      camera.attachControl();
  
      camera.applyGravity = true;
      camera.checkCollisions = true;
  
      camera.ellipsoid = new Vector3(1, 1, 1);
  
      camera.minZ = 0.45;
      camera.speed = 0.75;
      camera.angularSensibility = 4000;
  
      camera.keysLeft.push(37);
      camera.keysUp.push(37);
      camera.keysRight.push(39);
      camera.keysDown.push(40);
    }


  }
  